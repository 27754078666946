// ACTIONS
import { TOGGLE_LIGHT_MODE } from './ui-actions';

const initialState = {
  lightMode: 'light', // dark or light
};

export default function uiReducer(state = initialState, action) {
  if (action.type === TOGGLE_LIGHT_MODE) {
    return {
      ...initialState,
      lightMode: (state.lightMode === 'dark') ? 'light' : 'dark',
    };
  }

  return state;
}
