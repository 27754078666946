import { createTheme } from '@mui/material/styles';

const customTheme = (lightMode) => createTheme({
  // customize default theme here (https://mui.com/customization/default-theme/)

  palette: {
    mode: lightMode,
  },
});

export default customTheme;
