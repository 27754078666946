import React from 'react';
import PropTypes from 'prop-types';

// MUI
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

function Header({ LightModeToggle }) {
  return (
    <Grid item xs={12}>

      <Grid container justifyContent="space-between" alignItems="baseline">
        <Grid item>
          <Typography variant="h3">
            patsmitty.com
          </Typography>
        </Grid>

        <Grid item>
          {LightModeToggle}
        </Grid>
      </Grid>

      <Divider sx={{
        paddingY: 2,
      }}
      />
    </Grid>
  );
}

Header.defaultProps = {
  LightModeToggle: <span />,
};

Header.propTypes = {
  LightModeToggle: PropTypes.node,
};

export default Header;
