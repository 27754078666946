import { combineReducers } from 'redux';

// REDUCERS
import uiReducer from '../UI/redux/ui-reducer';

const rootReducer = combineReducers({
  ui: uiReducer,
});

export default rootReducer;
