import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import App from './App';

// import { worker } from './mock-service-worker/browser';

import configureStore from './redux/store';

const store = configureStore({});

// const isDev = (process.env.NODE_ENV === 'development');

// if (isDev === true) {
//   // this mocks all API calls in dev-mode; if you actually want to hit the endpoints for development, comment this out
//   worker.start();
// }

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
