import React from 'react';
import PropTypes from 'prop-types';

// MUI CORE
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

// MUI ICONS
import Brightness6Icon from '@mui/icons-material/Brightness6';

function LightModeToggle({ lightMode, toggleLightMode }) {
  return (
    <FormControlLabel
      control={(
        <Switch
          checked={lightMode === 'dark'}
          onChange={toggleLightMode}
          color="secondary"
        />
      )}
      labelPlacement="end"
      label={(
        <Brightness6Icon />
      )}
    />
  );
}

LightModeToggle.defaultProps = {
  lightMode: 'light',
  toggleLightMode: () => {},
};

LightModeToggle.propTypes = {
  lightMode: PropTypes.string,
  toggleLightMode: PropTypes.func,
};

export default LightModeToggle;
