import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

// MUI
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { ThemeProvider } from '@mui/material/styles';

// COMPONENTS
import Header from './components/Header';
import Footer from './components/Footer';
import LightModeToggle from './UI/components/LightModeToggle';
import Welcome from './components/Welcome';

// CUSTOM THEME
import customTheme from './material-ui/custom-theme';

// ACTIONS
import { toogleUILightMode } from './UI/redux/ui-actions';

function App() {
  const dispatch = useDispatch();

  const { lightMode } = useSelector((state) => state.ui);
  const theme = customTheme(lightMode);

  const padding = (92 + 110); // 92 (header) + 110 (footer)

  return (
    <ThemeProvider theme={theme}>

      <CssBaseline />

      <Container maxWidth="lg">

        <Router>

          <Grid container>

            <Header
              LightModeToggle={(
                <LightModeToggle
                  lightMode={lightMode}
                  toggleLightMode={() => dispatch(toogleUILightMode())}
                />
              )}
            />

            <Grid
              item
              xs={12}
              sx={{
                minHeight: `calc(100vh - ${padding}px)`,
              }}
            >

              <Switch>

                <Route path="/">
                  <Welcome />
                </Route>

              </Switch>

            </Grid>

            <Footer />
          </Grid>
        </Router>

      </Container>

    </ThemeProvider>
  );
}

export default App;
