import React from 'react';

// MUI
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

function Footer() {
  return (
    <Grid
      item
      xs={12}
      sx={{
        textAlign: 'center',
        paddingBottom: 19,
      }}
    >

      <Divider
        sx={{
          marginTop: 2,
          marginBottom: 4,
        }}
      />

      <Typography variant="subtitle2">
        &copy; 2021, patsmitty.com
      </Typography>
      <Typography variant="caption">
        When you visit or interact with our sites, services or tools, we may use cookies for storing information to help provide you with a better, faster, and safer experience.
      </Typography>
    </Grid>
  );
}

export default Footer;
