import React from 'react';

// MUI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

function Welcome() {
  return (
    <Grid
      container
      direction="row-reverse"
      justifyContent="flex-end"
      alignItems="center"
    >

      <Grid item xs={12} sm={1}>
        <img
          src="/images/construction-cone.png"
          alt="construction-cone.png"
          height={80}
          width={80}
        />
      </Grid>

      <Grid item xs={12} sm={11}>
        <Typography variant="body2">
          Welcome, patsmitty.com is under construction; check back soon.
        </Typography>

      </Grid>
    </Grid>
  );
}

export default Welcome;
